"use client";

import React, { FC, PropsWithChildren } from "react";
import {
  GlobalStateProvider,
  ShaApplicationProvider,
  StoredFilesProvider,
  MainLayout,
  useNextRouter,
} from "@shesha-io/reactjs";
import { GlobalDevExpressStyles, DevExpressReportingApplicationPlugin} from "@shesha-io/devexpressreporting";
import { AppProgressBar } from "next-nprogress-bar";
import { useTheme } from "antd-style";
import { EnterpriseApplicationPlugin } from "@shesha-io/enterprise";


/* NEW_TOOLBOXCOMPONENT_IMPORT_GOES_HERE */

export interface IAppProviderProps {
  backendUrl: string;
  applicationKey?: string;
}

export const AppProvider: FC<PropsWithChildren<IAppProviderProps>> = ({
  children,
  backendUrl,
  applicationKey
}) => {
  const nextRouter = useNextRouter();
  const theme = useTheme();

  const noAuthRoutes = [
    "/no-auth",
    "/login",
    "/account/forgot-password",
    "/account/reset-password",
  ];
  const noAuth = Boolean(
    noAuthRoutes.find((r) => nextRouter.path?.includes(r))
  );

  return (
    <GlobalStateProvider>
          <GlobalDevExpressStyles/>
      <AppProgressBar height="4px" color={theme.colorPrimary} shallowRouting />
      <ShaApplicationProvider
        backendUrl={backendUrl}
        router={nextRouter}
        noAuth={nextRouter.path?.includes('/no-auth')}
        applicationKey={applicationKey}
      >
        <EnterpriseApplicationPlugin>
        <DevExpressReportingApplicationPlugin>
          <StoredFilesProvider baseUrl={backendUrl} ownerId={""} ownerType={""}>
            {noAuth ? (
              <>{children}</>
            ) : (
              <MainLayout noPadding>{children}</MainLayout>
            )}
          </StoredFilesProvider>
          </DevExpressReportingApplicationPlugin>
        </EnterpriseApplicationPlugin>
      </ShaApplicationProvider>
    </GlobalStateProvider>
  );
};
